import envConfig  from "../env.config";

export const OPEN_SSO_MODAL_EVENT="openSsoModal";
export const CLOSE_SSO_MODAL_EVENT="closeSsoModal";
export const LOGIN_SUCCESS_CONSTANT = "loginSuccess";

export const ON_CONTINUE_CB= "continueAction"
export const SSO_LOGIN_STATE= "ssoLoginState"

export const DeviceConstants = {
  DESKTOP: "desktop",
  MOBILE: "mobile",
};
export const WhatsApp = {
  PATH: "https://wa.me/962791000983?text=",
};

export const configNavigationSso: Record<SsoScreenTypes, "close" | "back"> = {
  VALID_NUMBER: "close",
  INVALID_PASSWORD: "back",
  RETURNING_USER: "back",
  NEW_USER: "back",
  LOGIN_SCREEN: "close",
  LOGOUT: "close",
  VERIFY_VIA_SMS: "back",
  VERIFY_VIA_VIBER: "back",
  VERIFY_VIA_OTP: "back",
  LOGIN_SUCCESS: "close",
  UPDATED_MEMBER: "close",
  NEW_PASSWORD: "close",
  FORGOT_PASSWORD: "back",
  CHANGE_PASSWORD: "close",
  CHANGE_PHONE_NUMBER: "close",
    CONFIRMATION_SCREEN:"close",
  BAD_REQUEST: "back",
  UNPROCESSABLE_ENTITY: "back",
  REGISTER_NEW_USER: "close",
  NEW_ACCOUNT_CREATED: "close",
  PASSWORD_EDIT_SUCCESS: "close",
  OTP_VERIFIED: "close",
  INVALID_OTP: "back",
    ERROR_SCREEN:"close",
  VERIFY_VIA_OTP_LINK: "close",
    COMPLETE_REGISTER:"close",
  COMPLETE_USER_PROFILE_INFO:"close",
};

export const LoginAndRegistrationOtpActions = {
  ACTION_FORGET_PASSWORD: "ACTION_FORGET_PASSWORD",
  ACTION_VERIFY: "ACTION_VERIFY",
  ACTION_REGISTRATION: "ACTION_REGISTRATION",
  ACTION_LOGIN: "ACTION_LOGIN",
  ACTION_EDIT_MOBILE: "ACTION_EDIT_MOBILE",
};

export const onSmsClickFlowType = {
  FORGOT_PASSWORD: "ACTION_FORGET_PASSWORD",
  NEW_USER: "ACTION_REGISTRATION",
};

export const ScreenConstants = {
  VALID_NUMBER: "VALID_NUMBER",
  INVALID_PASSWORD: "INVALID_PASSWORD",
  RETURNING_USER: "RETURNING_USER",
  NEW_USER: "NEW_USER",
  LOGIN_SCREEN: "LOGIN_SCREEN",
  LOGOUT: "LOGOUT",
  VERIFY_VIA_SMS: "VERIFY_VIA_SMS",
  VERIFY_VIA_VIBER: "VERIFY_VIA_VIBER",
  VERIFY_VIA_OTP: "VERIFY_VIA_OTP",
  VERIFY_VIA_OTP_LINK: "VERIFY_VIA_OTP_LINK",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  UPDATED_MEMBER: "UPDATED_MEMBER",
  NEW_PASSWORD: "NEW_PASSWORD",
  FORGOT_PASSWORD: "FORGOT_PASSWORD",
  CHANGE_PASSWORD: "CHANGE_PASSWORD",
  CHANGE_PHONE_NUMBER: "CHANGE_PHONE_NUMBER",
    CONFIRMATION_SCREEN:"CONFIRMATION_SCREEN",
  BAD_REQUEST: "BAD_REQUEST",
  UNPROCESSABLE_ENTITY: "UNPROCESSABLE_ENTITY",
  REGISTER_NEW_USER: "REGISTER_NEW_USER",
    COMPLETE_REGISTER:"COMPLETE_REGISTER",
  NEW_ACCOUNT_CREATED: "NEW_ACCOUNT_CREATED",
  PASSWORD_EDIT_SUCCESS: "PASSWORD_EDIT_SUCCESS",
  OTP_VERIFIED: "OTP_VERIFIED",
  INVALID_OTP: "INVALID_OTP",
  ERROR_SCREEN:"ERROR_SCREEN",
  COMPLETE_USER_PROFILE_INFO: "COMPLETE_USER_PROFILE_INFO",
} as const;

export type SsoScreenTypes = (keyof typeof ScreenConstants);

export const AuthenticateQueryStringConstants = {
  COUNTRY: "country",
  DEVICE_UUID: "deviceUUID",
  PLATFORM: "platform",
  LANGUAGE: "language",
  DIALOGTYPE: "dialogType",
  CITY: "city",
  REDIRECT_URL: "redirectUrl",
  DEFAULT_URL : envConfig.OPENSOOQ_BASE_DOMAIN ,
};

export const CookiesConstants = {
  USER_INFO: envConfig.COOKIES_PREFIX + "userInfo",
  TGT_TIMESTAMP: "tgt_timestamp",
  TGT_TICKET: "tgt_ticket",
  AUTH: "auth",
  AUTH_V2: "auth_v2",
  TRACKING_ID: "tracking_id",
  DEVICE_UUID: "device_uuid",
  IDENTITY: "_identity",
  SOURCE: "source",
  COUNTRY: "ecountry",
  LOCALE: "NEXT_LOCALE",
    IS_LOGGED_IN: + "is_logged_in",
  RECENTLY_VIEWED: "_recentlyViewed",
  SUB: "sub",
  SPLASH_SCREEN_FOR_ONE_TIME_ONLY: "splash",
  HIDE_DOWNLOAD_BANNER_COUNTER: "hideDownloadBannerCounter",
  REMOVE_3RD_PARTIES: "remove3rdParties",
  OTP_CODE: "otp_code",
  OTP_NUMBER: "otp_number",
  LAST_VISITED_URL: "last_visited_page",
  CITY_ID: "cityId",
  LOGIN_COUNTRY_ABBREVIATION: "loginCountryAbbr",
  LOGIN_COUNTRY_CODE: "loginCountryCode",
  FIREBASE_FCM_TOKEN: "FIREBASE_FCM_TOKEN",
    SESSION:'session',
    PREVIOUS_LOGGING_ACTIONS:"prevAction",
    PREVIOUS_LOGGING_SCREEN_NAME:"prevScreen",
    PREVIOUS_LOGGING_WIDGET:"prevWidget",
    SESSION_FUNNEL_POINT_OF_ENTRY: "sessionFunnelPointOfEntry",
  SAVE_USER_PROFILE_DATA : "saveUserProfileData",
  POPUP_COUNT:"popupAppearanceDays"
};

//device_uuid_age
export const DEVICE_UUID_COOKIE_AGE_IN_DAYS = 360;

export const LocalStorageConstants = {
  LAST_SEARCH: "_lastSearch_v4",
  AUTH_TOKEN: "authToken",
  AUTH_TOKEN_EXPIRES: "authTokenExpires",
  SEARCH_ID: "search_id",
    PREVIOUS_LOGGING_ACTIONS:"loggingActions",
    PREVIOUS_LOGGING_SCREEN_NAME:"previousLoggingScreenName",
    PREVIOUS_LOGGING_WIDGET:"currentLoggingWidget",
    PHONE_NUMBER:"phone_number"
};

export const LogoutCookies = [
  "PHPSESSID",
  CookiesConstants.USER_INFO,
  CookiesConstants.AUTH,
  CookiesConstants.SUB,
  CookiesConstants.TRACKING_ID,
];

export const API_HEADERS_CONSTANTS = {
    RELEASE_VERSION: "9.4.02"
}
export const OTP_ONE_MINUTE_TIMER = {minutes: "00", seconds: "59"};
export const OTP_CODE_LENGTH = 6;
export const DIGIT_EN_REGEX = /^\d+$/;
export const DIGIT_AR_REGEX =/^[\u0660-\u0669]+$/;
export const DEFAULT_MINIMUM_PASSWORD_LENGTH = 6;
export const DEFAULT_MIN_NUMBER_LENGTH = 0;
export const DEFAULT_MAX_NUMBER_LENGTH = 12;
// sso errors code
export const ERROR_REQUIRED = 1001;
export const ERROR_PARALLEL_REQUEST = 1002;
export const ERROR_OTP_INVALID = 1003;
export const ERROR_UNEXPECTED = 1004;
export const ERROR_PHONE_INVALID = 1005;
export const ERROR_MEMBER_IS_NOT_ACTIVE = 1006;
export const ERROR_FOREIGN_COUNTRY = 1007 ;
export const ERROR_SAME_OLD_PHONE = 1008 ;
export const PHONE_NUMBER_ALREADY_USED = 1009;
export const ERROR_OTP_REUSED = 1010;
export const ERROR_OTP_EXPIRED = 1011;
export const ERROR_OTP_WRONG_BY_USER = 1012;
export const ERROR_SAME_OLD_EMAIL = 1013 ;